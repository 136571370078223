import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { CheckoutOrderType } from "../../../../redux/features/commerce/orders/types";
import { useNavigate, useParams } from "react-router-dom";
import { APP } from "../../../../_shared/utils/_urls";
import { useGetOrderQuery } from "../../../../redux/features/commerce/orders/ordersApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import FormInfo from "../../../../_shared/components/FormInfo";
import FormLink from "./components/FormLink";
import ResendEmailModal from "./components/ResendEmail";
import { useGetProductQuery } from "../../../../redux/features/commerce/products/productsApi";
import _ from "lodash";

const OrderDetailPage = () => {
  const [showResendEmail, setShowResendEmail] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<CheckoutOrderType>();

  const { data, isSuccess, refetch } = useGetOrderQuery(
    params.order_id ?? skipToken
  );

  const { data: product } = useGetProductQuery(order?.product_id ?? skipToken);

  const onResendEmail = (e: any) => {
    e.preventDefault();
    setShowResendEmail(true);
  };

  const onDoneResendingEmail = () => {
    setShowResendEmail(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setOrder(data);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth={false}>
        <Stack>
          <Grid container columns={2}>
            <Grid item sm={1}>
              <Breadcrumbs sx={{ mt: 1 }}>
                <Typography color="text.inherit">Commerce</Typography>
                <Typography color="text.inherit">
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => {
                      navigate(`${APP.ORDERS}`);
                    }}
                  >
                    Orders
                  </Link>
                </Typography>
                <Typography color="text.primary">{order?.order_ref}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              sm={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="text" color="secondary" onClick={onResendEmail}>
                Resend Email
              </Button>
            </Grid>
          </Grid>

          <Box>
            <FormInfo label="Order Ref" value={order?.order_ref} />
            <FormLink
              label="Customer"
              value={order?.user_name}
              route={`${APP.CUSTOMERS}`}
              id={order?.user_id}
            />
            <FormLink
              label="Stripe Id"
              value={order?.stripe_id}
              route="https://dashboard.stripe.com/payments"
              id={order?.stripe_id}
            />
            <FormInfo label="Stripe Status" value={order?.stripe_status} />
            <FormInfo label="Original Amount" value={order?.original_amount} />
            <FormLink
              label="Promo Code"
              value={order?.promo_code}
              route={`${APP.PROMO_CODES}`}
              id={order?.promo_code_id}
            />
            <FormInfo
              label="Promo Discount Amount"
              value={order?.promo_code_discount_amount}
            />
            <FormInfo label="Status" value={order?.status} />
            <FormLink
              label="Product"
              value={order?.product_name}
              route={`${APP.PRODUCT_GROUPS}`}
              id={product?.product_group_id}
              state={{ productIdToSet: order?.product_id }}
            />
            <FormInfo
              label="Add-On Items"
              value={
                <Stack>
                  {_.map(order?.items, (item) => {
                    return <Typography>{item.product_name}</Typography>;
                  })}
                </Stack>
              }
            />
            <FormInfo
              label="Details"
              value={
                <Box
                  sx={{ overflow: "auto", width: "100%", maxWidth: "500px" }}
                >
                  {order?.meta}
                </Box>
              }
            />
          </Box>
        </Stack>
      </Container>
      <ResendEmailModal
        orderId={order?.id}
        doneResendingEmail={onDoneResendingEmail}
        show={showResendEmail}
      />
    </React.Fragment>
  );
};

export default OrderDetailPage;
